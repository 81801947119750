import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarNotePipe } from './star-note.pipe';

@NgModule({
    declarations: [StarNotePipe],
    imports: [ CommonModule ],
    exports: [StarNotePipe],
    providers: [],
})
export class PipeModule {}