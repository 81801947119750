import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/list/list.module').then(m => m.ListPageModule)
  },
  { path: 'day-run-star', loadChildren: './pages/day-run-star/day-run-star.module#DayRunStarPageModule' },
  { path: 'hour-run-star', loadChildren: './pages/hour-run-star/hour-run-star.module#HourRunStarPageModule' },
  { path: 'year-run-star', loadChildren: './pages/year-run-star/year-run-star.module#YearRunStarPageModule' },
  { path: 'month-run-star', loadChildren: './pages/month-run-star/month-run-star.module#MonthRunStarPageModule' },
  { path: 'shan-xiang-pan', loadChildren: './pages/shan-xiang-pan/shan-xiang-pan.module#ShanXiangPanPageModule' },
  { path: 'search-dayun-byyear', loadChildren: './pages/search-dayun-byyear/search-dayun-byyear.module#SearchDayunByyearPageModule' },
  { path: 'life-run-star', loadChildren: './pages/life-run-star/life-run-star.module#LifeRunStarPageModule' },
  { path: 'search-jie-qi', loadChildren: './pages/search-jie-qi/search-jie-qi.module#SearchJieQiPageModule' },
  { path: 'gua-xiang', loadChildren: './pages/gua-xiang/gua-xiang.module#GuaXiangPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
