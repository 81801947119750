import { Injectable } from '@angular/core';



@Injectable()
export class appConfigService {
    url:{
        baseConfig:string,
        base:string,
    };
    /**
     *
     */
    constructor() {
        this.url={
            baseConfig:window['baseConfigUrl'] || "baseconfigapi.vxuepin.com",
            base:window['baseUrl'] || "https://xuankong.roberg.cn"
        }
    }
}
