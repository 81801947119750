import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: '首页',
      url: '/home',
      icon: 'home'
    },
   
    {
      title:'山向盘',
      url:'/shan-xiang-pan'
    },
    {
      title: '年飞星',
      url: '/year-run-star',
    },
    {
      title: '月飞星',
      url: '/month-run-star',
    },
    {
      title: '日飞星',
      url: '/day-run-star',
    },
    {
      title: '时飞星',
      url: '/hour-run-star',
    },
    {
      title:'命宫',
      url:'/life-run-star'
    },
    {
      title:'大运查询',
      url:'/search-dayun-byyear'
    },
    {
      title:'节气查询',
      url:'/search-jie-qi'
    },
    {
      title:'八卦卦象',
      url:'/gua-xiang'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
