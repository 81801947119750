import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoadingController } from '@ionic/angular';
import { tap, finalize } from 'rxjs/operators';


@Injectable()
export class NoopInterceptor implements HttpInterceptor {
    /**
     *测试拦截机
     */
    loading:any;
     timer:any;
     requestCount:number=0;
    constructor(private loadingController: LoadingController) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCount++;
        if(!this.timer){
            this.timer=  setTimeout(async () => {
                console.log('我执行了');
               
                    this.loading = await this.loadingController.create({
                      message: '正在加载...',
                      duration: 15000
                    });
                    await this.loading.present();
                    
                
            }, 1000);
        }
        
        
        //console.log(`${req.url}的请求方法为：${req.method}`);
        return next.handle(req).pipe(
            tap(
                event => {
                    // There may be other events besides the response.
                    // if (event instanceof HttpResponse) {
                    //     console.log(event);

                    // }
                },
                error => {
                    console.log("请求失败");
                }),
            finalize(() => {
                this.requestCount--;
                if(this.requestCount==0){
                    clearTimeout(this.timer);
                    this.timer=null;
                    if(this.loading){
                        this.loading.dismiss();
                        this.loading=null;
                    }
                    
                }
            })

        );
    }
}