import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { RunStarJiugongComponent } from './run-star-jiugong/run-star-jiugong.component';
import { ChineseDigitPipe } from '../pipes/chinese-digit.pipe';
import { MyPopoverComponent } from './my-popover/my-popover.component';

@NgModule({
    declarations: [RunStarJiugongComponent,ChineseDigitPipe,MyPopoverComponent],
    entryComponents:[RunStarJiugongComponent,MyPopoverComponent],
    imports: [ CommonModule,
        IonicModule.forRoot(),
    ],
    exports: [RunStarJiugongComponent,ChineseDigitPipe,MyPopoverComponent],
    providers: [],
})
export class AppComponentModule {}