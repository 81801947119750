import { Component, OnInit,Input } from '@angular/core';
import { VM } from 'src/app/services/vm';

@Component({
  selector: 'app-run-star-jiugong',
  templateUrl: './run-star-jiugong.component.html',
  styleUrls: ['./run-star-jiugong.component.scss'],
})
export class RunStarJiugongComponent implements OnInit {

  @Input()
  runStarList:VM.PositionRunStar[]=[];

  constructor() { }

  getRunStart(index:number):VM.PositionRunStar{

    if(index>=this.runStarList.length){
      return null;
    }
    return this.runStarList[index];
  }
  ngOnInit() {}

}
