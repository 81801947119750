import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VM } from './vm';
import { appConfigService } from '../config/appConfig';
import { CommonService } from './CommonService';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class RunStarServiceService {
  private handleError: HandleError;
  
  constructor(public http: HttpClient, public config: appConfigService,public comm:CommonService,httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('RunStarService');
  }

  public getZuoXiangPan(data: VM.GetShanXiangPamVM): Observable<VM.ResponseData> {
    let url = `${
      this.config.url.base
      }/api/shanXiang/getShanXianPan`;

    return this.http.post<VM.ResponseData>(url, data)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public getShanXiangPanByXiangShouDegree(degree:number,dayun:number): Observable<VM.ResponseData> {
    let url = `${
      this.config.url.base
      }/api/shanXiang/getShanXiangPanByXiangShouDegree?degree=${degree}&daYun=${dayun}`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getYearRunStar(year:number):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getYearRunStar?year=${year}`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public getYearRunStarByDate(date:string):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getYearRunStarByDate`;

    return this.http.post<VM.ResponseData>(url,{date:date})
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getYearRunStarByNow():Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getYearRunStarByNow`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getMonthRunStar(year:number,month:number):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getMonthRunStar?year=${year}&month=${month}`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public getMonthRunStarByDate(date:string):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getMonthRunStarByDate`;

    return this.http.post<VM.ResponseData>(url,{date:date})
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public getMonthRunStarByNow():Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getMonthRunStarByNow`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getDayRunStar(date:string):Observable<VM.ResponseData>{
    
    let url = `${
      this.config.url.base
      }/api/index/getDayRunStar`;

    return this.http.post<VM.ResponseData>(url,{date:date})
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getDayRunStarByCurrent():Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getDayRunStarByCurrent`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getHourRunStar(date:string):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getHourRunStar`;

    return this.http.post<VM.ResponseData>(url,{date:date})
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public getHourRunStarByNow(date:string):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getHourRunStarByNow`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getLastDaYunList():Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getLastDaYunList`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }
  public calDaYunByYear(year:number):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/calDaYunByYear?year=${year}`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getLifeRunStar(dto:{date:string,sex:string}):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getLifeRunStar`;

    return this.http.post<VM.ResponseData>(url,dto)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public addAdvice(dto:VM.AdviceVM):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/advice/add`;

    return this.http.post<VM.ResponseData>(url,dto)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getAdviceList():Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/advice/getList`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }

  public getYearJieQi(year:number):Observable<VM.ResponseData>{
    let url = `${
      this.config.url.base
      }/api/index/getYearJieQi?year=${year}`;

    return this.http.get<VM.ResponseData>(url)
    .pipe(
      catchError(this.handleError<VM.ResponseData>('retData'))
    );

  }



}
