import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'starNote'
})
export class StarNotePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let note = value;
    switch (value) {
      case 1:
        note = "一白贪狼"
        break;
      case 2:
        note = "二黑巨门"
        break;
      case 3:
        note = "三碧禄存"
        break;
      case 4:
        note = "四绿文曲"
        break;
      case 5:
        note = "五黄廉贞"
        break;
      case 6:
        note = "六白武曲"
        break;
      case 7:
        note = "七赤破军"
        break;
      case 8:
        note = "八白左辅"
        break;
      case 9:
        note = "八白左辅"
        break;
      default:
        break;
    }
    return note;
  }

}
