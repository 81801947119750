import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RunStarServiceService } from './services/run-star-service.service';
import { appConfigService } from './config/appConfig';
import { CommonService } from './services/CommonService';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { AppComponentModule } from './components/app-component.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopInterceptor } from './config/noop-interceptor';
import { PipeModule } from './pipes/pipe.module';
// import { StarNotePipe } from './pipes/star-note.pipe';
// import { ChineseDigitPipe } from './pipes/chinese-digit.pipe';
// import { RunStarJiugongComponent } from './components/run-star-jiugong/run-star-jiugong.component';

@NgModule({
  declarations: [AppComponent,],
  entryComponents: [],
  exports:[],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppComponentModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'myIonicdb',
      driverOrder: ['indexeddb', 'websql', 'sqlite']
    }),
   
    AppRoutingModule,
    PipeModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    RunStarServiceService,
    appConfigService,
    CommonService,
    HttpErrorHandler,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
