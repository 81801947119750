import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-popover',
  templateUrl: './my-popover.component.html',
  styleUrls: ['./my-popover.component.scss'],
})
export class MyPopoverComponent implements OnInit {

  constructor() { }

  dismiss(){
   if(window["myPopoper"]){
     window["myPopoper"].dismiss();
   }
  }
  ngOnInit() {}

}
