import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chineseDigit'
})
export class ChineseDigitPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let chineseDigit = value;
    switch (value) {
      case 1:
        chineseDigit = "一"
        break;
      case 2:
        chineseDigit = "二"
        break;
      case 3:
        chineseDigit = "三"
        break;
      case 4:
        chineseDigit = "四"
        break;
      case 5:
        chineseDigit = "五"
        break;
      case 6:
        chineseDigit = "六"
        break;
      case 7:
        chineseDigit = "七"
        break;
      case 8:
        chineseDigit = "八"
        break;
      case 9:
        chineseDigit = "九"
        break;
      default:
        break;
    }
    return chineseDigit;
  }

}
