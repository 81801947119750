import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ToastController,PopoverController } from '@ionic/angular';
import { PickerController } from '@ionic/angular';
import { MyPopoverComponent } from '../components/my-popover/my-popover.component';
/*
  Generated class for the CommonProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  mypop:any;
  constructor(public storage: Storage, public toastController: ToastController, public pickercontroller: PickerController,public popoverCtrl:PopoverController) {
  }
  //创建多选列
  async openPicker(numColumns = 1, numOptions = 5, multiColumnOptions, callback) {
    const picker = await this.pickercontroller.create({
      columns: this.getColumns(numColumns, numOptions, multiColumnOptions),
      buttons: [
        {
          text: '取消',
          role: 'cancel'
        },
        {
          text: '確定',
          handler: value => {
            console.log(`Got Value ${value}`);
            callback(JSON.stringify(value))
          }
        }
      ]
    });
    await picker.present();
  }
  getColumns(numColumns, numOptions, columnOptions) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push({
        name: `col-${i}`,
        options: this.getColumnOptions(i, numOptions, columnOptions)
      });
    }
    return columns;
  }
  getColumnOptions(columnIndex, numOptions, columnOptions) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text: columnOptions[columnIndex][i % numOptions],
        value: i
      });
    }
    return options;
  }
  public async showPop(ev: any,content:string=null){
    
      const popover = await this.popoverCtrl.create({
        component: MyPopoverComponent,
        event: ev,
        translucent: true
      });
      console.log(popover);
      
      console.log(popover.innerHTML);
     
      await popover.present();
      if(content){
        popover.getElementsByClassName("mypopover")[0].innerHTML=content;
      }
     window["myPopoper"]=popover;
      return ;
    
  }
  public dismissPop(){
    if(this.mypop){
      this.mypop.dismiss();
    }

  }

  /**
   * showInfo
   */
  public async showInfo(msg: string, duration: number = 2000, position: string=null) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration
    });
    toast.present();
  }
  /**
   * dataAdd
   */
  public dataAdd(key: string, data: any) {
    this.storage.set(key, data);
  }
  /**
   * dataGet
   */
  public dataGet<T>(key: string): Promise<T> {
    return this.storage.get(key)
      .then((value) => {
        if (value) {
          return value as T;
        }
        else {
          return {} as T;
        }
      })
      .catch(this.handleError);
  }
  /**
   * dataDelete
   */
  public dataDelete(key: string) {
    this.storage.remove(key);
  }
  /**
   * cookieAdd
   */
  cookieAdd(key: string, value: string, expire?: Date): void {
    if (expire) {
      document.cookie = key + "=" + value + ";expires=" + expire.toUTCString();
    }
    else {
      document.cookie = key + "=" + value + ";";
    }
  }
  cookieAddM(key: string, value: string, expireMinute: number): void {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireMinute * 60 * 1000);
    document.cookie = key + "=" + value + ";expires=" + expire.toUTCString();
  }
  /**
   * cookieGet
   */
  public cookieGet(key: string): any {
    let arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
      return arr[2];
    else
      return null;
  }
  /**
   * cookieDelete
   */
  public cookieDelete(key) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = this.cookieGet(key);
    if (cval != null) {
      document.cookie = key + "=" + cval + ";expires=" + exp.toUTCString();
    }
  }

  dateToString(date:Date,isShort:boolean=false){ 
    let year = date.getFullYear(); 
    let month =(date.getMonth() + 1).toString(); 
    let day = (date.getDate()).toString();  
    let hours=date.getHours();
    let minute=date.getMinutes();
    let second=date.getSeconds();
    if (month.length == 1) { 
        month = "0" + month; 
    } 
    if (day.length == 1) { 
        day = "0" + day; 
    }

    var dateTime = `${year}/${month}/${day} ${hours}:${minute}:${second}`;
    if(isShort){
      dateTime = `${year}/${month}/${day}`;
    }
    return dateTime; 
  }
  dateStrToStanderStr(dateStr:string){
    let tmpDate=new Date(dateStr);
     dateStr=this.dateToString(tmpDate);
    return dateStr;
  }
  //将json参数化
  toQueryString(obj) {
    let result = [];
    for (let key in obj) {
      key = encodeURIComponent(key);
      let values = obj[key];
      if (values && values.constructor == Array) {
        let queryValues = [];
        for (let i = 0, len = values.length, value; i < len; i++) {
          value = values[i];
          queryValues.push(this.toQueryPair(key, value));
        }
        result = result.concat(queryValues);
      }
      else {
        result.push(this.toQueryPair(key, values));
      }
    }
    return result.join('&');
  }
  toQueryPair(key, value) {
    if (typeof value == 'undefined') {
      return key;
    }
    return key + '=' + encodeURIComponent(value === null ? '' : String(value));
  }
  handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
